// components
import { showToast, showToastErr, showToastOk, showToastWarn } from 'components/basic/Toast/Toast'
// i18n
import { t } from 'i18n'
// services
import { AuthService } from 'services/AuthService'

/**
 * List of supported error codes - used for generic translations of Toast msgs or special use cases
 *
 * @example
 * 201 - for creating new entries
 * 204 - for displaying NoData for tables or other data-fetching components
 * 401 - for kicking the user out of the app
 * 403 - for  performing an action user doesn't have permissions for
 * 408 - for taking too long
 *
 * etc. etc.
 */
type HTTPCodes =
  | 0 //   HTTP request failed - `catch` was reached
  | 200 // ok
  | 201 // created
  | 204 // no_content
  | 400 // bad_request
  | 401 // unauthorized
  | 402 // payment_required
  | 403 // forbidden
  | 404 // not_found
  | 408 // request_timeout
  | 409 // conflict
  | 422 // conflict
  | 500 // internal_server_error
  | 502 // bad_gateway
  | 503 // service_unavailable

//const endpoint = 'https://portal-staging.webswing.org/api/v1'
const endpoint = 'http://cp.webswing.test/api/v1'

/**
 * Base API endpoint URL
 */
const BASE_URL: string = window.location.origin.includes('localhost')
  ? endpoint
  : `${window.location.origin}/api/v1`
/**
 * Type of fetching method used by the app
 */
const FETCH_TYPE: 'fetch' | 'graphQL' = 'fetch'

/**
 * Configuration for the unified `API()` used by every single API call declared within `api/endpoints`
 * This interface is local and used only here, by the main `API()` method only
 */
interface IAPICallConfig<IBody = object, IOptions = {}>
  extends /* RequestInit, */ IAPICallParams<IOptions> {
  // #TODO: technically `options` should be part of body
  bAuthentificate?: boolean

  files?: boolean

  body?: IBody
  /**
   * Enpoint's URL
   */
  URL: string
  /**
   * collection of used HTTP request types supported by `fetch()`
   */
  method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE'

  onUploadProgress?: (progress: number) => void
}

/**
 * Custom parameters passed to every API call declared within `api/endpoints`
 */
export interface IAPICallParams<IOptions, IFallbackValue = null> {
  /**
   * Fallback value to return if the API call fails
   */
  fallBackValue?: IFallbackValue
  /**
   * Additional options - may vary based on API call
   */
  options?: IOptions
  /**
   * Config for custom Toast behaviour
   *
   * 1st param is message
   *
   * 2nd param is duration
   */
  toast?: string | null
}

export interface IAPILaravelResponse<T> {
  message: string
  error: boolean
  data: T
}

export interface IAPIResponse<T> {
  /**
   * `data` of the response
   */
  content?: T | null
  /**
   * Whether the API call succeeded or not
   */
  success: boolean
  /**
   * Short message (from BE) describing the API call's status (whether it succeeded or failed)
   */
  message: string
  /**
   * Status code of the API call
   */
  status: HTTPCodes
}

/**
 * Toast handler method
 * There are 3 scenarios:
 *
 *  `toastMsg === 'string'` - custom toast provided from api call - TOP priority, overrides everything
 *
 *  `toastMsg === undefined` - override not specified - use the defaul value
 *
 *  `toastMsg === null`- specificaly defined as null - dont show any toasts
 *
 * @param defaultMsg text displayed by default when `overrideMsg === undefined`
 * @param overrideMsg text that overrides the default value
 * @param type type of a toast, if `undefined` shows regular, non-styled toast
 */
function handleToast(
  defaultMsg: string,
  overrideMsg?: string | null,
  type?: 'err' | 'ok' | 'warn'
) {
  if (overrideMsg === null) return

  const msg = overrideMsg || defaultMsg
  switch (type) {
    case 'err':
      showToastErr(msg)
      break
    case 'ok':
      showToastOk(msg)
      break
    case 'warn':
      showToastWarn(msg)
      break
    case undefined:
    default:
      showToast(msg)
      break
  }
}
// credits: https://stackoverflow.com/a/42483509
const buildFormData = (formData: FormData, data: any, parentKey?: string) => {
  if (data && typeof data === 'object' && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
    })
  } else {
    const value = data === null ? '' : data

    formData.append(parentKey!, value)
  }
}

/**
 * Generic unified method for executing API calls
 * @param apiCallParams configuration & payload data for the API call
 */
export async function CallAPI<T, U>(
  apiCallParams: IAPICallConfig
) /* :  Promise<IAPIResponse<T> */ {
  if (FETCH_TYPE === 'fetch') {
    const { URL, method, bAuthentificate, toast, files } = apiCallParams
    let { body } = apiCallParams
    // HTTP Request headers
    const headers: Headers | Record<string, string> = {
      Accept: 'application/json',
    }

    if (!files) {
      headers['Content-Type'] = 'application/json;charset=utf-8'
    }

    if (files && ['GET', 'POST'].indexOf(method) === -1) {
      if (body) {
        ;(body as any)['_method'] = method
      } else {
        body = {
          _method: method,
        }
      }
    }

    try {
      // attach `accessToken` to the `headers`
      if (bAuthentificate) {
        const accessToken: string | null = sessionStorage.getItem('accessToken')
        if (accessToken) {
          headers['X-API-AUTH'] = accessToken
          headers['Authorization'] = `Bearer ${accessToken}`
        }
      }

      let finalBody = undefined
      if (body) {
        if (files) {
          finalBody = new FormData()
          buildFormData(finalBody, body)
        } else {
          finalBody = JSON.stringify(body)
        }
      }

      // send the fetch HTTP request & JSON.parse the response
      let responseRaw: Response
      try {
        responseRaw = await fetch(BASE_URL + URL, {
          body: finalBody, // body isnt allowed for GET requests
          headers,
          method,
        })
      } catch (e) {
        responseRaw = { status: 0, ok: false, statusText: (e as Error).message } as Response
        handleToast((e as Error).message, (e as Error).message, 'warn')
      }

      const { status, ok, statusText } = responseRaw

      let obj: any
      try {
        obj = await responseRaw.json()
      } catch (e) {
        obj = {
          message: statusText,
          error: true,
        }
      }
      let { data } = obj
      const { message, error, ...otherProps } = obj

      let success = ok
      if ('undefined' !== typeof error) {
        success = !error
      }

      // check for other props returned than the default and wrap if any
      if (Object.keys(otherProps).length > 1) {
        data = { ...otherProps, data }
      }

      switch (status as HTTPCodes) {
        // Action successfull
        case 200:
          handleToast(message, toast, 'ok')
          break

        // Creation successfull
        case 201:
          handleToast(t.TOAST_201, toast, 'ok')
          break

        // Action failed - something went wrong
        case 400:
        case 401:
          AuthService.kickUser()
          handleToast(message, toast, 'err')
          break

        case 403:
        case 404:
        case 409:
        case 422:
          handleToast(message, toast, 'err')
          break

        // Action timed-out
        case 408:
          handleToast(t.TOAST_408, toast, 'err')
          return { message, status, success: false } as IAPIResponse<T>

        // Error occured on the server side
        case 500:
        case 502:
        case 503:
          handleToast('Issue occured on the server side ! \n' + message, toast, 'err')
          return { message, status, success: false } as IAPIResponse<T>

        default:
      }

      // data as ----> ? (data as T) : (res as T),        // #CHECK
      return { content: data, message, status, success } as IAPIResponse<T>
    } catch (err) {
      const errString = JSON.stringify(err)
      showToastErr(errString)

      return {
        content: err,
        message: errString,
        status: 0,
        success: false,
      } as IAPIResponse<T>
    }
  } else {
    // use GraphQL
    // #TODO
  }
}

export  function uploadFileWithProgress<T>(config: IAPICallConfig): Promise<IAPIResponse<T>>{
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const { URL, method, body, bAuthentificate, onUploadProgress } = config;

    xhr.open(method, BASE_URL + URL, true);

    // Set headers
    if (bAuthentificate) {
      const accessToken = sessionStorage.getItem('accessToken');
      if (accessToken) {
        xhr.setRequestHeader('X-API-AUTH', accessToken);
        xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
      }
    }

    xhr.setRequestHeader('Accept', 'application/json');

    // Handle progress
    if (xhr.upload && onUploadProgress) {
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          onUploadProgress(progress);
        }
      };
    }

    // Handle response
    xhr.onload = () => {
      const { status, responseText } = xhr;
      let response: IAPIResponse<T>;

      try {
        response = JSON.parse(responseText);
      } catch (e) {
        response = {
          content: null,
          message: xhr.statusText,
          status: status as HTTPCodes,
          success: false,
        };
      }

      if (status >= 200 && status < 300) {
        handleToast(response.message, 'Uploaded sucessfuly!', 'ok')
        resolve(response);
      } else {
        handleToast(response.message, 'Something went wrong. Try later.', 'err')
        reject(response);
      }
    };

    // Handle error
    xhr.onerror = () => {
      reject({
        content: null,
        message: xhr.statusText,
        status: xhr.status as HTTPCodes,
        success: false,
      });
    };

    // Prepare form data
    const formData = new FormData();
    if (body) {
      buildFormData(formData, body);
    }

    // Send request
    xhr.send(formData);
  });
}
