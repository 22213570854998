import React from 'react';
import cx from 'classnames';
import css from './Progress.module.scss';

export interface ICircleProgressProps {
  className?: string;
  classText?: string;
  size?: number;
  strokeWidth?: number;
  percentage: number;
}

export const Progress = ({ className, classText, size = 36, strokeWidth = 2, percentage }: ICircleProgressProps) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;

  return (
    <svg
      className={cx(css.circleProgress, className)}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg">
      <circle
        className={css.background}
        strokeWidth={strokeWidth}
        fill="none"
        cx={size / 2}
        cy={size / 2}
        r={radius}
      />
      <circle
        className={css.foreground}
        strokeWidth={strokeWidth}
        fill="none"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
      />
      <text
        className={css.text}
        x="50%"
        y="50%"
        dy=".3em"
        textAnchor="middle">
        {`${percentage}%`}
      </text>
    </svg>
  );
};