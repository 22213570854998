// API
import { CallAPI, uploadFileWithProgress } from '../APIUtils';

export class IssuesEndpoint {
  async ListActive(page = 1) {
    return CallAPI({ method: 'GET', URL: `/issues/active?page=${page}`, bAuthentificate: true });
  }

  async ListHistory(page = 1) {
    return CallAPI({ method: 'GET', URL: `/issues/history?page=${page}`, bAuthentificate: true });
  }

  async View(id: number) {
    return CallAPI({ method: 'GET', URL: `/issues/${id}`, bAuthentificate: true });
  }

  async EmailsAndAttachments(id: number) {
    return CallAPI({ method: 'GET', URL: `/issues/${id}/emails`, bAuthentificate: true });
  }

  async NewTicker(body: any) {
    return CallAPI({ method: 'POST', URL: `/issues`, bAuthentificate: true, body, files: true });
  }

  async closeStatus(id: number) {
    return CallAPI({ method: 'POST', URL: `/issues/${id}/close`, bAuthentificate: true });
  }

  async FileUpload(issueId: number, body: any, p0: (progress: any) => void) {
    return uploadFileWithProgress({ method: 'POST', URL: `/issues/${issueId}/file-shares`, bAuthentificate: true, body, files: true, onUploadProgress: p0 });
  }

  async removeFile(issueId: number, fileId: number) {
    return CallAPI({ method: 'DELETE', URL: `/issues/${issueId}/file-shares/${fileId}`, bAuthentificate: true });
  }

  async DownloadFile(issueId: number, fileId: number) {
    return CallAPI({ method: 'GET', URL: `/issues/${issueId}/file-shares/${fileId}/download`, bAuthentificate: true });
  }
}
