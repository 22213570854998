// core
import React, { useCallback, useEffect, useState } from 'react';

// components
import { Card, CardContent, Chip, Divider, formatDate, Heading, Loader, PageHeader, List, DateString, Tabs, Tab, Icon, Button, Tooltip } from 'components';

// translation
import { t } from 'i18n';

import cx from 'classnames';
import API from 'api';

// styles
import css from './Issues.module.scss';

// partials
import { LabelWithText } from './Partials/LabelWithText';
import { IIssue } from '../../../api/interfaces/IIssue';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { IssueStatus } from './Partials/IssueStatus';
import { ResponseResolution } from './Partials/ResponseResolutionChip';
import { changeTitle } from '../../routes';
import { AuthService } from 'services/AuthService';
import { Progress } from 'components/basic/Loader/variants/Progress/Progress';

export const humanFileSize = (size: number | undefined): string => {
  if (!size) return '0b';

  const i = Math.floor(Math.log(size) / Math.log(1024));
  const fractioned = size / Math.pow(1024, i);
  return fractioned.toFixed(2) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
};

interface IEmailFolder {
  id: number
  name: string
}

interface IEmail {
  id: string
  email_folder: IEmailFolder
  from: string
  from_name: string
  from_contact_id: number | null
  reply_to: string | null
  reply_to_name: string | null
  reply_to_contact_id: number | null
  recipients: {
    contact_id: number | null
    recipient_name: string
    recipient_email: string
    recipient_type: string
    contact: null
  }[]
  subject: string
  message_received_at: string
  message: string
}

 export interface ISharedFile {
    id: number
    filename: string
    extension: string
    size: number
    contact: string | null
    created_at: string
    updated_at: string
  }

interface IAttachment {
  created_at: string
  download_url: string
  extension: string
  file_size: number
  filename: string
  id: number
  is_image: number
  type: number
}

interface IEmailsAndAttachmentResponse {
  data: IEmail[]
  attachments: IAttachment[]
}

export const IssueDetail = () => {
  const [issue, setIssue] = useState<IIssue | undefined>(undefined);
  const [emails, setEmails] = useState<IEmail[]>([]);
  const [attachments, setAttachments] = useState<IAttachment[]>([]);
  const [files, setFiles] = useState<ISharedFile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<number>(0)
  const history = useHistory();

  const { params } = useRouteMatch();

  const { currentUser } = AuthService;

  const handleTabChange = useCallback((tabIndex: number) => {
    if (activeTab !== tabIndex) {
      setActiveTab(tabIndex)
    }
  }, [activeTab])

  useEffect(() => {
    if (!issue) {
      setLoading(true);
      API.IssuesEndpoint.View(params.id)
        .then(res => {
          if (res?.status === 403) {
            history.push('/support')
          }
          if (res?.success) {
            const issue = res.content as IIssue;
            setIssue(orig => ({ ...orig, ...issue }));
            setFiles(issue.files);
            changeTitle(`Ticket ${issue.ticket_number} | Support`)
            API.IssuesEndpoint.EmailsAndAttachments(params.id).then(res2 => {
              if (res2?.success) {
                const iEmails = (res2.content as IEmailsAndAttachmentResponse).data;
                setEmails(iEmails);
                setAttachments((res2.content as IEmailsAndAttachmentResponse).attachments);
                if (iEmails.length > 0) {
                  setExpanded(orig => [...orig, iEmails[0].id]);
                }
              }
            });
          }
          setLoading(false);
        });
    }
  }, [issue, params.id, history]);

  const toggleContent = (id: string) => {
    setExpanded(orig => {
      const index = orig.findIndex(i => i === id);
      if (index >= 0) {
        orig.splice(index, 1);
      } else {
        orig.push(id);
      }
      return [...orig];
    });
  };

  const download = (fileId: number) => {
      API.IssuesEndpoint.DownloadFile(params.id, fileId).then(res2 => {
          if (res2?.success && res2.content) {
            window.open(res2.content as string, 'Download');
        }
      });
  };

  const removeFile = (fileId: number) => {
        setLoadingFile(true);
      API.IssuesEndpoint.removeFile(params.id, fileId).then(res2 => {
          if (res2?.success) {
            const index = files.findIndex(item => item.id === fileId);
            files.splice(index,1);
        }
      })
      .finally(()=> {
        setLoadingFile(false);
    });
  };

  const processFiles = useCallback(
    (e: any) => {
        setLoadingFile(true);
        const target = e.target;
        if (e.target.files!.length > 0) {
            const data = {
                'file': e.target.files![0],
                'filename': e.target.files![0].name
            }
            API.IssuesEndpoint.FileUpload(params.id, data, (progress) => {
              setUploadProgress(progress);
            })
            .then((res: any) => {
                if (!res?.error) {
                    setFiles((cur)=>[...cur,res.data as ISharedFile]);
                }
            })
            .finally(()=> {
                setLoadingFile(false);
                setUploadProgress(0);
                target.value = '';
            })
        }
    },
    [files, setFiles, params.id]
  )

  return (
    <div>
      <PageHeader back="/support" title={`${t.TICKET} ${issue ? issue.ticket_number : '...'}`}/>
      <Loader.Line bLoading={loading}/>
      {issue && (<div className={css.ticketDetails}>
        <div className={css.leftSide}>
            <Tabs
                className={css.tabsTicket}
                defaultTab={activeTab}
                onChangeTab={handleTabChange}>
                <Tab label="Ticket details" className={css.tabNoOverflow}>
                    <TicketInfo issue={issue} />
                </Tab>
                <Tab bDisabled={issue.support_ticket_status.name === 'closed'} label="Files">
                    <Loader.Line bLoading={loadingFile}/>
                    <Card className={css.attachments}>
                        <div className={css.headerFiles}>
                            <Heading variant="h3" title="Shared Files"/>
                            <div className={css.flex}>
                              {uploadProgress > 0 && (
                                <div className={css.uploadProgress}>
                                  <Progress percentage={Math.round(uploadProgress)} />
                                </div>
                              )}
                              <Button
                                  color="neutral"
                                  icon="file_upload"
                                  onClick={() => (document.querySelector('input#files') as HTMLElement).click()}
                              />
                              <input
                                  type="file"
                                  style={{ display: 'none' }}
                                  id="files"
                                  accept=".log,.jpg,.jpeg,.png,.pdf,.xlsx,.p7z,.docx,.config,.conf,.java,.mov,.mp4,.out,.patch,.sh,.txt,.yml,.zip,.rar,.tar"
                                  onChange={processFiles}
                              />
                            </div>
                        </div>
                        <List
                            rowHeight={60}
                            item={files => (
                                <div className={cx(css.attachment, css.file)}>
                                    <Icon className={css.fileIcon} color={'blue'} name={'upload_file'} />
                                    <div onClick={()=>download(files?.id!)}>
                                        <div className={css.filename}>{files?.filename}</div>
                                        <div className={css.fileInfo}>
                                            <Tooltip content={`size: ${humanFileSize(files?.size)}`}>
                                        <span>
                                            Author: {files?.contact && currentUser?.name === files?.contact  ? 'You': files?.contact}
                                            <DateString format="DD.MM.YYYY HH:mm" value={files?.created_at}/>
                                        </span>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    {
                                        files?.contact &&
                                        <Button
                                            color="neutral"
                                            icon="delete"
                                            size="small"
                                            onClick={()=>removeFile(files?.id!)}
                                            className={css.removeFile}
                                        />
                                    }
                                </div>
                            )}
                            collection={files}
                        />
                    </Card>
                    {attachments.length > 0 && <>
                        <Card className={cx(css.attachments, css.attachmentsTop)}>
                            <div className={css.headerFiles}>
                                <Heading variant="h3" title="Email Attachements"/>
                            </div>
                            <List
                                rowHeight={60}
                                item={attachment => (
                                    <div className={css.attachment}>
                                        <Chip
                                            label={attachment?.type === 1 ? 'Inbox' : 'Sent'}
                                            className={css.attachmentChip}
                                            color={attachment?.type === 1 ? 'blue' : 'neutral'}
                                        />
                                        <div>
                                            <div className={css.filename}>{attachment?.filename}</div>
                                            <div className={css.fileInfo}>
                                                <DateString format="DD.MM.YYYY HH:mm" value={attachment?.created_at}/> - {humanFileSize(attachment?.file_size)}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                collection={attachments}
                            />
                        </Card>
                    </>}
                </Tab>
            </Tabs>
        </div>

        <div className={css.ticketMessage}>
          <div className={css.ticketMessageHeader}>
            <ResponseResolution tooltip={'First response time'} value={issue.response_until} limit={issue.level_response_time_limit}/>
            <ResponseResolution tooltip={'Resolution time'} value={issue.resolve_until} limit={issue.level_resolve_time_limit}/>
          </div>

          <div className={css.emails}>
            {emails.map(email => {
              const cc = email.recipients.filter(recipient => recipient.recipient_type === 'cc');
              const lines = email.message.split('\n').map((line, index) => (
                <div key={index}>
                  {line.replace(/&nbsp;/g, '\u00A0')}
                </div>
              ));
              return (
                <Card key={email.id} className={css.ticketMessageCard}>
                  <CardContent className={css.ticketHeadingContent} onClick={() => toggleContent(email.id)}>
                    <Heading
                      // buttons={[<Button key="moreButton" color="neutral" icon="more_vert"/>]}
                      title={email.from_name}
                      variant="h2"
                    />

                    <div className={css.inboxDiv}>
                      <Chip label={email.email_folder.name} color={email.email_folder.id === 1 ? 'blue' : 'neutral'}/>
                      <p>{email.subject}</p>
                    </div>

                    <div className={css.reciever}>
                      <div className={css.recieverInfo}>
                        <div className={css.contacts}>
                          <strong>To</strong>
                          <div>{email.recipients.filter(recipient => recipient.recipient_type === 'to').map((recipient, index) => <Chip key={index} label={recipient.recipient_name || recipient.recipient_email}/>)}</div>
                        </div>
                        {cc.length > 0 && (
                          <div className={css.contacts}>
                            <strong>Cc</strong>
                            <div>{cc.map((recipient, index) => <Chip key={index} label={recipient.recipient_name}/>)}</div>
                          </div>
                        )}
                      </div>
                      <span>{formatDate(email.message_received_at, { format: 'DD.MM.YYYY HH:mm' })}</span>
                    </div>
                  </CardContent>
                  <Divider/>
                  {expanded.includes(email.id) && (
                    <CardContent className={css.text}>
                      {lines}
                    </CardContent>
                  )}
                </Card>
              );
            })}
          </div>
        </div>
      </div>)}
    </div>
  );
};

const TicketInfo = ({issue}: {issue:IIssue}) => {
    const history = useHistory();
    const closeStatus = (id: number) => {
        return API.IssuesEndpoint.closeStatus(id).then(res => {
            if (res?.success) {
                history.push('/support')
            } else {
                // @ts-ignore
                errorCallback(res.content.errors)
            }

            return res;
        })
    }

    return (
        <Card>
            <CardContent className={css.ticketDetailsTop}>
              <div className={css.status}>
                <IssueStatus color={issue.support_ticket_status.name}/>
                {issue.support_ticket_status.name}
              </div>
              <LabelWithText label="Subject" text={issue.subject}/>
              <LabelWithText label="Solver" text={issue.owner?.username || 'N/A'}/>
              <LabelWithText label="Created" text={formatDate(issue.created_at, { format: 'DD.MM.YYYY - HH:mm' })}/>
                {
                  issue.support_ticket_status.name !== 'closed' && (
                    <Button label="Mark as Resolved" color="blue" size="small" className={css.resolved} onClick={() => closeStatus(issue.id)}  />
                  )
              }

            </CardContent>
            <Divider/>
            <CardContent className={css.ticketDetailsBottom}>
              {/*<LabelWithText label="Company" text={issue.company?.name || 'N/A'}/>*/}
              <LabelWithText label="From" text={issue.contact?.name || issue.from_name || 'N/A'}/>
              <LabelWithText label="E-mail" text={issue.from_email || 'N/A'}/>
            </CardContent>
          </Card>
    );
}
